.contacts {
    flex-wrap: wrap-reverse;
    &-left {
        &_cases {
            padding-top: 48px;
            padding-right: 48px;
            padding-bottom: 72px;
            width: 100%;
        }
        &_phone {
            background-color: $blue;
            color: $white;
            flex: 1;
            min-height: 320px;
            a {
                width: 100%;
                text-align: center;
                &:hover {
                    color: $white;
                }
            }
        }
        &_cases {
            background-color: $gray-200;
            height: unset;
            min-height: 440px;
            .cases-slide {
                padding: 0;
                &-header {
                    margin-bottom: 48px;
                    height: unset;
                    // align-items: flex-end;
                    &_company-logo {
                        display: none;
                    }
                }
                &_feedback {
                    margin-bottom: 40px;
                }
            }
        }
    }
    &-left,
    &-form_wrapper {
        width: 50%;
    }
    &-form {
        &_wrapper {
            background-color: $dark-gray;
            color: $white;
            // margin: 0 auto;
            padding-top: 86px;
            padding-bottom: 72px;
        }
        &_title {
            margin-bottom: 92px;
            max-width: 560px;
        }
        .btn {
            margin-top: 26px;
        }
    }
}

@media (max-width: 1024px) {
    .contacts {
        &-form {
            &_wrapper {
                padding-top: 72px;
                padding-bottom: 64px;
            }
            &_title {
                margin-bottom: 64px;
            }
        }
    }
}

@media (max-width: 998px) {
    .contacts {
        &-left {
            &_phone {
                padding-top: 96px;
                padding-bottom: 96px;
                min-height: 240px;
            }
            &_cases {
                padding-top: 32px;
                padding-right: 32px;
            }
        }
        &-left,
        &-form_wrapper {
            width: 100%;
        }
        &-form {
            &_wrapper {
                padding-top: 72px;
                padding-bottom: 64px;
            }
            &_title {
                margin-bottom: 64px;
                max-width: unset;
            }
            .btn {
                width: 100%;
                padding: 16px 32px;
                margin-top: 22px;
            }
        }
    }
}

@media (max-width: 640px) {
    .contacts-form {
        &_wrapper {
            padding-top: 40px;
            padding-bottom: 48px;
        }
        &_title {
            margin-bottom: 32px;
        }
    }
    .contacts {
        &-left_phone {
            padding-top: 84px;
            padding-bottom: 84px;
            min-height: 200px;
        }
        // .contacts-left_cases {
        //     display: none;
        // }
    }
}