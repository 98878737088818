.table-header {
    @include T12;
    padding: 0 0 16px 0;
}

@media(max-width: 640px) {
    .table-header {
        align-items: flex-start;
        
    }
}