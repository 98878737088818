.footer {
    color: $dark-gray;

    &-top, &-bottom {
        @include row;
        justify-content: flex-start;
        align-items: flex-start;
    }

    &-top {
        padding-top: 96px;
        padding-bottom: 92px;
    }

    &-bottom {
        padding-top: 72px;
        padding-bottom: 72px;   
    }

    &-col {
        width: calc((100% - 16px*4)/4);
        padding: 0 16px;
    }

    &-menu {

        &_title {
            margin-bottom: 32px;
        }

        &_list-item {

            &:not(:last-child) {
                margin-bottom: 12px;
            }
        }
}

}

@media (max-width: 768px) {
    .footer-col {
        width: 100%;
        padding: 0;

        &:not(:last-child){ 
            margin-bottom: 32px;
        }
    }

    .footer-bottom {
        padding-top: 40px;
        padding-bottom: 48px;
    }

    .footer-top {
        padding-top: 48px;
        padding-bottom: 40px;
    }

    .footer-logo {
        @include row-center;
        margin-bottom: 8px;
    }

    .footer-menu {

        .footer-col:not(:last-child) {
            margin-bottom: 40px;
        }
        &_title {
            margin-bottom: 16px;
        }
        
    }
}