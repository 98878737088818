// url('./SuisseIntl/Ptrootuimedium.woff') format('woff'),
// url('./SuisseIntl/Ptrootuimedium.ttf') format('truetype');
@font-face {
    font-family: 'SuisseIntl-Regular';
    src:  local('SuisseIntl'), local('SuisseIntl-Regular'), 
      url('./SuisseIntl/SuisseIntl-Regular.otf') format('opentype');
    // font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'SuisseIntl-Medium';
    src: local('SuisseIntl'), local('SuisseIntl-Medium'),
      url('./SuisseIntl/SuisseIntl-Medium.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
}
