.header {
  color: $white;
  position: relative;
  transition: 0.4s transform;

  @include position($zIndex: 5, $bottom: unset);
  position: fixed;
  z-index: 102;

  // &--absolute {
  //     @include position($zIndex: 5, $bottom: unset);
  //     position: fixed;
  //     z-index: 102;
  // }

  &-container {
    position: relative;
  }

  &-logo {
    display: block;
    max-width: 120px;
    max-height: 72px;
  }

  &-top {
    min-height: 120px;
    overflow: visible;
  }

  &-center {
    align-self: stretch;
  }

  &-menu {
    height: 100%;

    &_item {
      align-self: stretch;
      a {
        height: max-content;
      }
      &:not(:last-child) {
        margin-right: 40px;
      }
    }
  }

  &-submenu_item {
    text-align: center;
    flex: 1;

    p {
      padding: 48px 0;
      width: 100%;
      height: 100%;
      cursor: pointer;

      display: flex;
      justify-content: center;
      align-items: center;
    }

    &:hover {
      @include bg-blue;
    }
    &:not(:last-child) {
      border-right: 1px solid $gray-300;
    }
  }

  &-submenu {
    @include position(
      $top: unset,
      $width: 100%,
      $translateY: 100%,
      $bgColor: transparent
    );

    &_list {
      align-items: stretch;
    }
    visibility: hidden;

    &--open {
      background-color: inherit;
      visibility: visible;
    }
  }

  &_lang-select {
    margin-right: 20px;

    &_header {
      padding: 16px 0;
    }

    &_body {
      @include position(
        $zIndex: 2,
        $top: calc(40px + 16px),
        $translateX: -50%,
        $left: 100%
      );
    }
  }

  &_contact-btn {
    border-color: rgba(255, 255, 255, 0.2);
    padding: 8px 20px;
  }
  svg {
    fill: $white;
  }

  &--white {
    background-color: $white;
    color: $dark-gray;

    svg {
      fill: $dark-gray;
    }
    .header {
      &-submenu {
        background-color: $white;
      }

      &_contact-btn {
        border-color: $gray-300;
      }
    }

    .header_contact-btn:hover {
      @include bg-blue();
      border-color: $blue;
    }
  }

  &--submenu-open {
    .header-menu_item:first-child {
      @include hover();
    }
  }
}

.header_burger-btn {
  min-width: 40px;
  min-height: 16px;
  position: relative;
  display: none;

  border-top: 2px solid $white;

  &:before,
  &:after {
    @include pseudo(
      $height: 2px,
      $width: 100%,
      $left: 0,
      $transform: translate(0, -50%)
    );
    background-color: $white;
  }

  &:before {
    top: calc(50% + 2px);
  }

  &:after {
    bottom: calc(50% + 1px);
  }

  &--open {
    border-color: transparent;
    // border-color: $dark-gray;;
    &::before,
    &::after {
      display: none;
    }

    svg {
      fill: $dark-gray;
    }
  }
}

.header-mobile {
  background-color: $white;
  position: fixed;
  // position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 106;

  transform: translateX(-100%);
  transition: 0.6s all;

  @include col;
  justify-content: flex-start;

  overflow-y: auto;
  display: none;
  &--open {
    transform: translateX(0);
    display: flex;
  }

  &_content {
    padding: 0 24px 32px 24px;
    @include col-bw;
    // height: 100%;
    // flex: 1;
    width: 100%;
    height: max-content;

    svg {
      fill: $dark-gray;
    }
  }

  &_top {
  }
}

@media (max-width: 768px) {
  .header_burger-btn {
    display: block;
  }

  .header-submenu {
    display: none;
  }

  .header-mobile_content {
    .header-submenu {
      display: block;
    }
  }

  .header--white {
    .header_burger-btn {
      border-color: $dark-gray;
      &::before,
      &::after {
        background-color: $dark-gray;
      }
    }

    .header_burger-btn--open {
      border-color: transparent;
      // background-color:  $dark-gray;

      &::before,
      &::after {
        // background-color:  $dark-gray;

        display: none;
        background-color: transparent;
      }
    }
  }

  .header-container {
    height: 100%;
    @include col;
    align-items: stretch;
    justify-content: stretch;
    overflow-y: auto;
  }

  .header-menu {
    height: unset;
    margin-bottom: 24px;

    &--open {
      svg {
        transform: rotate(-90deg);
      }
    }
  }

  .header_contact-btn {
    border-color: $gray-300;
    width: 100%;
  }

  .header-menu_item {
    flex: unset;
    width: 100%;

    a {
      padding: 16px 0 14px 0;
      text-align: left;
      font-size: 24px;
      line-height: 26px;
      text-transform: uppercase;
    }

    &:not(:last-child) {
      border-bottom: 1px solid $dividers;
      margin: 0;
    }

    .link {
      width: 100%;
      justify-content: space-between;

      &:hover {
        color: inherit;
      }
    }

    .header-submenu_list {
      padding: 8px 0;
    }

    .header-submenu_item {
      flex: unset;
      width: 100%;
      padding: 0;
      text-transform: unset;

      p {
        @include T15;
        padding: 0 0 16px 0;
        text-transform: unset;
        text-align: left;

        display: block;
      }

      &:not(:last-child) {
        border-right: none;
      }
    }
  }

  .header--white .header-menu_item:first-child {
    color: $dark-gray;

    svg {
      fill: inherit;
    }
  }
  .header_lang-select {
    margin: 0 0 24px 0;

    &_body {
      right: unset;
      left: 0;
      transform: translate(0, 0);
    }
  }
  .header-top {
    width: 100%;
    min-height: unset;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .header {
    &-logo {
      height: 48px;
      width: 48px;
    }
    &-center,
    &-right {
      display: none;
    }
  }
}
