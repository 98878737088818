@import "./CasesSlide.scss";

.slider {
    &-arrows {

        svg {
            fill: #fff;
            cursor: pointer;

            &:hover {
                fill: $blue;
            }
            
            &:first-child{
                margin-right: 32px;
            }
        }

        &--gray svg {
            fill: $gray-400;
        }

        &--black svg {
            fill: $black;
        }
    }
}