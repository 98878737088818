
 .cases-slide {
        // padding: 72px 80px 96px 80px;
        // padding-bottom: 96px;
        // padding-top: 72px;
        height: 100%;
        &_wrapper {
            padding: 72px 80px 96px 80px;
        }

        &-header {
            // margin-bottom: 224px;
            margin-bottom: 56px;
            align-self: stretch;
            height: 120px;
            flex-wrap: nowrap;
            &_left {
                max-width: calc(100% - 120px);
            }

            &_company-logo {
                @include image-bg;
                width: 100%;
            }
        }

        &_feedback {
            margin-bottom: 64px;
            max-width: 460px;
            font-size: 15px;
            line-height: 22px;
        }

        &-bottom {
                            // flex-wrap: nowrap;

                            .T-gray {
                                white-space: break-spaces;
                            }
            // width: max-content;
            &_left {
                margin-right: 24px;
            }

            &_right {
                flex: 1;
            }
          
        }
        &-quotes, &-photo {
            @include round($size: 72px);
            @include image-bg;
        }

        &-quotes {
            background-color: $blue;
            position: relative;

            &:before{
                @include pseudo($content: url("../../assets/icons/quotes.svg"), $width: 20px, $height: 17px, $left: 50%, $top: 50%, $transform: translate(-50%, -50%));
            }
        }

        &-photo {
            object-fit: cover;
            margin-left: -20px;
            z-index: 3;
            background-color: $blue;
        }

        &_author {
            margin-bottom: 2px;
        }
}

@media(max-width: 768px) {
    .cases-slide {

        &-header {
            // margin-bottom: 128px;
            height: 96px;
        }

        &_feedback {
            margin-bottom: 40px;
            max-width: 602px;
            font-size: 18px;
            line-height: 24px;
        }

        .btn-icon--middle {
            width: 56px;
            height: 56px;
          }
    }
}

@media(max-width: 640px) {
    .cases-slide {
        // padding-bottom: 36px;
        
        &-header {
            // margin-bottom: 56px;
            height: 80px;
            flex-wrap: nowrap;

           
        }

        &_feedback {
            margin-bottom: 32px;
        }

        &-bottom {
            &_left {
                margin-bottom: 20px;
                margin-right: 0;
            }

            &_right {
                width: 100%;
                flex: unset;
            }
        }

    }}