// main colors (text, bg, icons, links)
$black: #000;
$white: #fff;
$dark-gray: #151515; //main text
$blue: #0085FF; // links, text-mark

// gray colors
$gray-200: #F4F4F8;
$gray-300: #D1D8E1;
$gray-400: #94A0B1; // secondary text
$dividers: #D1D8E1;


$success: #1ED77E;
$fail: #FF461E;
$warning: #FF9737; 
$deep-blue: #006CCF; // links hover
$dark-blue: #015AAB;

// gradients
$main-gradient: linear-gradient(102.11deg, #0085FF 2.42%, rgba(0, 133, 255, 0.5) 49.68%, rgba(0, 133, 255, 0) 96.94%), rgba(0,0,0,0.5);
$gradient-green: linear-gradient(102.11deg, #0BD18A 2.42%, rgba(11, 209, 138, 0.5) 49.68%, rgba(11, 209, 138, 0) 96.94%), rgba(0,0,0,0.5);
$gradient-red: linear-gradient(104.04deg, #FF461E 0%, rgba(255, 43, 30, 0.5) 50%, rgba(255, 43, 30, 0) 100%), rgba(0,0,0,0.5);
$gradient-purple: linear-gradient(104.04deg, #6C47FF 0%, rgba(108, 71, 255, 0.5) 50%, rgba(108, 71, 255, 0) 100%), rgba(0,0,0,0.5);

$border-radius: 12px;
$box-shadow: 0px 4px 64px rgba(0, 0, 0, 0.1);
$transition: 0.3s;


$breakpoint-1: 1280px; // 16 col
$breakpoint-2: 1072px; // 16 col
$breakpoint-3: 928px; // 16 col
$breakpoint-4: 688px; // 16 col
$breakpoint-5: 328px; // 4 col

// fonts

@mixin subheader {
  font-size: 17px; // 1440-1920 20px
  line-height: 24px; // (120%);

  @media (min-width: 1920px) {
    font-size: 20px;
    line-height: 26px; // (110%);
  }
}

@mixin subheader-bold {
  font-size: 17px; // 1440-1920 20px
  line-height: 24px; // (120%);
  font-weight: 500;
  font-family: 'SuisseIntl-Medium', sans-serif;

  @media (min-width: 1920px) {
    font-size: 20px;
    line-height: 26px; // (110%);
  }
}

@mixin T15 {
  font-size: 15px; // 1440-1920 17px
  line-height: 24px;// (150%);

  @media (min-width: 1920px) {
    font-size: 17px;
    line-height: 24x; // (110%);
  }
}

@mixin T14 {
  font-size: 14px; // 1440-1920 17px
  line-height: 23px; // (150%);
}

@mixin T12 {
  font-size: 12px; // 1440-1920 17px
  line-height: 18px; // (150%);
}

@mixin T15-bold {
  font-size: 15px;
  line-height: 24px; // (150%);
  font-weight: 500;
  font-family: 'SuisseIntl-Medium', sans-serif;
}

@mixin T14-bold {
  font-size: 14px;
  line-height: 22px; // (150%);
  font-weight: 500;
  font-family: 'SuisseIntl-Medium', sans-serif;
}

@mixin T12-bold {
  font-size: 12px;
  line-height: 18px; // (150%);
  font-weight: 500;
  font-family: 'SuisseIntl-Medium', sans-serif;
}


@mixin h1--large {
  font-size: 80px; // >1920 104px
  line-height: 88px; // (110%);

  @media (min-width: 1920px) {
    font-size: 104px;
    line-height: 202px; // (110%);
  }

  @media (max-width: 1200px) {
    font-size: 72px;
    line-height: 78px; // (110%);
  }

  @media (max-width: 1024px) {
    font-size: 62px;
    line-height: 68px; // (110%);
  }

  @media (max-width: 767px) {
    font-size: 40px;
    line-height: 44px; // (110%);
  }
}

@mixin h1 {
font-size: 56px;  // 1440-1920 72px
line-height: 62px; // (110%);

  @media (min-width: 1920px) {
    font-size: 72px;
    line-height: 80px; // (110%);
  }

  @media (max-width: 1024px) {
    font-size: 46px;
    line-height: 52px; // (121%);
  }

  @media (max-width: 640px) {
    font-size: 28px;
    line-height: 34px; // (121%);
  }
}

@mixin h2 { 
  font-size: 40px; // 1440-1920 56px
  line-height: 48px; // (120%);

  @media (min-width: 1920px) {
    font-size: 56px;
    line-height: 62px; // (110%);
  }


  @media (max-width: 640px) {
    font-size: 24px;
    line-height: 30px; // (125%);
  }
}

@mixin h3 {
  font-size: 32px; // 1440-1920 48px
  line-height: 35px; // (110%);

  @media (min-width: 1920px) {
    font-size: 48px;
    line-height: 54px; // (110%);
  }


  @media (max-width: 768px) {
    font-size: 20px;
    line-height: 24px; // (118%);
  }
}

@mixin h4 {
  font-size: 24px; // 1440-1920 32px
  line-height: 32px; //(132%);

  @media (min-width: 1920px) {
    font-size: 32px;
    line-height: 40px; // (110%);
  }
  
  @media (max-width: 768px) {
    font-size: 18px;
    line-height: 24px; // (133%);
  }
}

