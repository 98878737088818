.privacy-page {
  &_wrapper {
    max-width: 800px;
    padding: 0 24px;
    margin: calc(132px + 120px) auto 170px auto;
  }

  &_title {
    font-size: 56px;
    line-height: 62px;
    margin-bottom: 104px;
    text-align: center;
    text-transform: uppercase;
  }

  &_content {
    a {
      color: $blue;
      display: inline-block;
    }

    h2 {
      margin-bottom: 24px;
      font-weight: 500;
      font-family: 'SuisseIntl-Medium', sans-serif;
    }

    ol,
    ul {
      li:not(:last-child) {
        margin-bottom: 24px;
      }

      & + h2 {
        margin-top: 72px;
      }
    }

    p {
      & + ul {
        margin-top: 24px;

        li {
          margin-bottom: 0;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .privacy-page {
    &_wrapper {
      margin: calc(40px + 88px) auto 96px auto;
    }

    &_title {
      font-size: 28px;
      line-height: 34px;
      margin-bottom: 40px;
    }

    &_content {
      ol,
      ul {
        & + h2 {
          margin-top: 48px;
        }
      }
    }
  }
}
