@mixin row {
  display: flex;
  flex-wrap: wrap;
}

@mixin row-center {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

@mixin row-bw {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@mixin row-end {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

@mixin col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

@mixin col-bw {
  display: flex;
  flex-direction: column;

  justify-content: space-between;
}

@mixin col-center {
  display: flex;
  flex-direction: column;

  justify-content: center;
}


@mixin position(
  $pos: absolute,
  $left: 0,
  $right: 0,
  $top: 0, 
  $bottom: 0,
  $bgColor: initial, 
  $translateX: 0,
  $translateY: 0,
  $zIndex: 1,
  $width: initial
) {
  position: $pos;
  left: $left;
  right: $right;
  bottom:$bottom;
  top: $top;
  background-color: $bgColor;
  transform: translate($translateX, $translateY);
  z-index: $zIndex;
  width: $width;
}

@mixin transition($property: "all" ) {
  transition: $property $transition ease-in-out;
}

@mixin pseudo(
  $content: '',
  $top: unset,
  $bottom: unset,
  $left: unset,
  $right: unset,
  $width:unset,
  $height: unset,
  $transform: initial
) {
  position: absolute;
  content: $content;
  top: $top;
  bottom: $bottom;
  left: $left;
  right: $right;
  width: $width;
  height: $height;
  transform: $transform
}

@mixin round($size: '', $bgColor: 'none', $bgImage: 'unset') {
  border-radius: 50%;
  width: $size;
  height: $size;
  background-color: $bgColor;
  background-image: $bgImage;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@mixin square($size: '', $bgColor: $gray-200, $borderRadius: 0) {
  width: $size;
  height: $size;
  border-radius: $borderRadius;
  background-color: $bgColor;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}


@mixin image-bg ($img: 'unset') {
  background-size: cover;
  background-image: $img;
  background-repeat: no-repeat;
  background-position: center center;
  // background-color: $gray-200;
}



@mixin border(
  $top: unset,
  $bottom: unset,
  $left: unset,
  $right: unset,
  $width:100%,
  $height: 1px,
  $translateX: initial,
  $translateY: -50%,
) {
  position: absolute;
  content: "";
  background-color: $gray-300;
  height:$height;
  width: $width;

  top: $top;
  bottom: $bottom;
  left: $left;
  right: $right;

  transform: translate($translateX, $translateY);
}

@mixin  transition-colors {
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, border-color  0.2s ease-in-out;

  svg {
    transition: fill 0.2s ease-in-out;
  }
}

@mixin hover (
  $bgColor: inherit,
  $color: $blue
  ){
  @include transition-colors();
  background-color: $bgColor;
  color: $color;

  svg {
    fill: $color;
  }
}

@mixin bg-black {
  background-color: $dark-gray;
  color: $white;

  svg {
    fill: $white;
  }
}

@mixin bg-gray {
  background-color: $gray-200;
  color: $dark-gray;

  svg {
    fill: $dark-gray;
  }
}

@mixin bg-blue {
  background-color: $blue;
  color: $white;

  svg {
    fill: $white;
  }
}

@mixin bg-warn {
  background-color: $warning;
  color: $white;

  svg {
    fill: $white;
  }
}

@mixin bg-fail {
  background-color: $fail;
  color: $white;

  svg {
    fill: $white;
  }
}

@mixin bg-fail {
  background-color: $fail;
  color: $white;

  svg {
    fill: $white;
  }
}

@mixin bg-success {
  background-color: $success;
  color: $white;

  svg {
    fill: $white;
  }
}

// @mixin bg-overlay($zIndex: 3) {
//   @include position($zIndex:3);
//   background:linear-gradient(0deg,rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
// }
@mixin mask($size: inherit) {
  position: absolute;
  top: -$size;
  left: -$size;
  width: $size*2;
  height: $size*2;
  box-shadow:inset 0 0 0px $size;
  color: $white;
  border-radius: 50%;
  transition: box-shadow 0.3s;
  pointer-events: none;
}
