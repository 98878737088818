.drop-down {
    position: relative;

    &_header {
        cursor: pointer;
    }
    &_body {
        background-color: $white;
        border: 1px solid $gray-300;

        padding: 18px 20px;
        min-width: 160px;
        height: max-content;
        box-sizing: border-box;
        display: none;

        &--open {
            display: block;
        }
    }

    &_item {
        color: $dark-gray;
        @include transition($property: color);
        cursor: pointer;

        &:not(:last-child){ 
            margin-bottom: 16px;
        }

        &--active {
            color: $gray-400;
        }
    }
}