.piracy {
    overflow: hidden;
    padding-top: 120px;

    .main-section {
        padding-top: 132px;
        padding-bottom: 48px;
    }
    &-rating_genres {
        max-width: 400px;
    }

    &-rating_genres {
        flex: 0.5;

        &-title {
            max-width: 368px;
        }
        &-list {
            margin: 48px -8px -6px 0;
        .tag {
            margin: 6px 8px;
        }
    }
      
    }

    &-rating_table {
        flex: 0.5;
        &-row { 
            width: 100%;
            padding: 44px 0;
        }


        &-cell {
            text-align: center;
            align-items: center;
        }
   

        &-content {
            .piracy-rating_table-cell   {
                flex: 0.5;

                &_film {
                    flex: 1;
                    align-items: flex-start;
                    padding: 0;
                    text-align: left;
                } 
            }

            
        }

        &-header .piracy-rating_table-cell {
            width:25%;
            justify-content: center;
           span  {
            text-align: center;
            max-width: 168px;
           }
        }

        &-desc {
            margin-top: 48px;
        }
    }
    // svg {
    //     fill: $white;
    // }
}
@media (max-width: 998px) {

    .piracy {
        .main-section {
            padding-top: 172px;
            padding-bottom: 70px;
        }
        &-rating_genres  {
            max-width: unset;
           
            &-list {
                display: flex;
                overflow-x: auto;
                margin: 24px -8px 32px -24px;
                padding-left: 24px;
                width: calc(100% + 48px);

            }
        }

        &-rating_genres, &-rating_table  {
            flex: unset;
            width: 100%;
        }
    }
}

@media (max-width: 768px) {
    .piracy {
        padding-top: 88px;

        .main-section {
            padding-top: 40px;
            padding-bottom: 56px;
        }

        &-main_title {
            padding-bottom: 0;
        }
       &-rating{
        .table-header{
            width: 100%;

            .table-cell {
                justify-content: flex-start;
            }
            span{
                text-align: left;
            }
        }
       }
}}

@media (max-width: 640px) {
  
    .piracy-rating {
        &_table {
            border-top: 1px solid $dividers;

            &-desc {
                margin-top: 36px;
            }

        }
        &_table-row {
            padding: 24px 0 30px;
        }

        &_table-cell {
            align-items: flex-start;
            justify-content: center;
        }

        &_table-content {
            .piracy-rating_table-cell_film {
                width: 100%;
                flex: unset;
                margin-bottom: 20px;
            }
        }
        .table-header{
            width: 100%;
            padding-bottom: 8px;
         
            &:first-child span {
                max-width: 96px;
            }
            span{
                text-align: left;
                justify-content: flex-start;
            }


            &:after {
                display: none;
            }
        }
    }
}

