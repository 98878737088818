.pricing {
    overflow-x: hidden;
    padding-top: 120px;
    &-main {
        padding-top: 132px;
        padding-bottom: 198px;
    }

    &-header {
        margin-bottom: 56px;
    }

    &-list {
        margin-bottom: 92px;
        flex-wrap: wrap;

        .table-header {
            width: 50%;
            margin-left: auto;
        }

        .table-cell {
            text-align: left;
        }
    }

    &-list_item {
        width: 100%;
        padding-top: 40px;
        padding-bottom: 40px;
        border-top: 1px solid $dividers;
        border-bottom: 1px solid $dividers;

        &--row {
            .pricing-list_item {

                &-title {
                    flex:1;
                }

                &-center, &-btn {
                    flex: 0.5;
                }

                &-center_title {

                    .h4 {
                        @include T15-bold;
                     }

                     .h3 {
                        @include h4;
                     }
                }

                &:last-child  {
                    .h3 {
                        @include T15;
                    }
                }
            }
        }

        &-counter {  
            line-height: 24px; 
            position: relative; 
            cursor: pointer;            
            svg {
                fill: $gray-400;
                margin-right: 8px;
            }
            
        }

        &-center {

            &_price {
                display: inline;

                // span {
                //     @include T15;
                // }
            }

            &_desc {
                padding-right: 16px;
                max-width: 240px;
            }
        }

        a {
            display: inline-flex;
        }

    }

    &-list_video {
        margin-bottom: 192px;

        .pricing-list_item {
            width: calc(100%/4);
            padding: 40px 32px  48px  32px;
            display: flex;
            flex-direction: column;
            // border-left: 1px solid $dividers;
            // border-right: 1px solid $dividers;

            &:after {
                right: -1px
            }

            &-center {
                margin: 64px 0 32px 0;
                flex: 1;
                &_title {
                    margin-bottom: 24px;

                    .h4 {
                        max-width: 180px;
                        display: inline-block;
                    }
                }
            }
        }
    }

    &-low{
            position: relative;
            @include image-bg();

            
        &_content {
            justify-content: space-between;
            // padding-bottom: 72px;
            .T-gray {
                color: $white;
            }

            .h1 {
                margin-bottom: 0;
                flex: 1;
                @include col-center;
            }
            @include position($zIndex: 4);
            color: $white;
            position: relative;

            // padding-top: 200px;
            // padding-bottom: 200px;
            min-height: 720px;
        }
    }

}

@media (max-width: 1200px) {
    .pricing{
        &-list_video {
            .pricing-list_item {
                padding: 40px 24px 48px 24px;
            }
        }
    }
}


@media (max-width: 998px) {
    .pricing{
        &-header {
            h2 {
                max-width: 320px;
            }
        }
        &-list_video {
            margin-bottom: 136px;
            border-left: 1px solid $dividers;

            .pricing-list_item {
                width: calc(100%/2);
                border-bottom: 1px solid $dividers;

                &:before {
                    display: none;
                }

                &-center {
                    margin: 32px 0 16px 0;

                    &_title {
                        margin-bottom:16px ;
                    }

                    &_desc {
                        padding-right: 0;
                    }
                }
            }
        }
       
    }
}

@media (max-width: 768px) {
    .pricing {
        padding-top: 88px;

        &-main  {
            padding-top: 40px;
        }

        &-header{
            margin-bottom: 40px;
        }

       &-low_content {
           padding-top: 40px;
           padding-bottom: 64px;
           min-height: unset;
           .copyright-holders_subtitle{
               margin-bottom: 96px;
           }
       }


       &-list_header {
        display: none;
    }

    &-list {
        margin-bottom: 0;

        .table-header {
            width: 100%;
            flex-wrap: nowrap;
        }
    }

    &-list_item {
        padding: 24px 0 40px 0;

        &-header {
            margin-bottom: 8px;
        }

        &--row {
            align-items: center;
           .pricing-list_item {

            &-header {
                padding: 0;
                .table-cell span {
                    max-width: 96px;
                }
            }
           

            &-title {
                margin-bottom: 28px;
                width: 100%;
                flex: unset;
            }

            &-center {
            //     width: 100%;
            align-self: flex-end;            }
            }
         }
    }

    &-list_video {
        border-left:none ;
        width: calc(100% + 48px);
        margin-left: -24px;
        margin-bottom: 64px;

        .pricing-list_item {
            padding: 40px 24px 48px 24px;
            width: calc(100%);

            .table-header {
                display: none;
            }
            &-center{
                margin-top: 56px;
                &_price {
                font-size: 56px; 
                line-height: 62px
            }}

            .h4 {
                font-size: 24px;
                line-height: 30px;
            }
            
            &:before, &:after {
                display: none;
            }
        }
    
    }
    }
}

@media (max-width: 640px) {
    .pricing {

        &-header_switcher {
                width: 100%;
                margin: 24px auto 0;
        }

        &-main {
            padding-top: 40px;
            padding-bottom: 40px;
        }

        &-list_header {
            display: none;
        }

        &-list {
            margin-bottom: 0;

            .table-header {
                width: 100%;
                flex-wrap: nowrap;
            }
        }

        &-list_item {
            padding: 24px 0 40px 0;


            &--row {
                align-items: center;
               .pricing-list_item {

                &-header {
                    padding: 0;
                    .table-cell span {
                        max-width: 96px;
                    }
                }
               

                &-title {
                    margin-bottom: 28px;
                    width: 100%;
                    flex: unset;
                }

                // &-center {
                //     width: 100%;
                //     flex: unset;
                // }
                }
             }
        }
    
        &-list_video {
            border-left:none ;
            width: calc(100% + 48px);
            margin-left: -24px;
            margin-bottom: 64px;

            .pricing-list_item {
                padding: 40px 24px 48px 24px;
                width: calc(100%);

                .table-header {
                    display: none;
                }
                &-center{
                    margin-top: 56px;
                    &_price {
                    font-size: 56px; 
                    line-height: 62px
                }}

                .h4 {
                    font-size: 24px;
                    line-height: 30px;
                }
                
                &:before, &:after {
                    display: none;
                }
            }
        
        }
    }
}