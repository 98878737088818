.btn {
    // @include subheader;
    @include row;
    border-radius: 80px;
    font-weight: 500;
    transition: background-color 0.2s ease-in-out, border-color 0.2s  ease-out, color 0.2s  ease-in-out,fill 0.2s  ease-in-out;
    white-space: nowrap;
    outline: none;
    width: max-content;
    position: relative;
    display: flex;
    justify-content: center;

    &:disabled{
      cursor: default;
      color: $gray-400; 
    }
  }
  
  // SIZES
  .btn {
    &--small {
      padding: 8px 16px;
    }

    &--middle {
      padding: 16px 24px;
    }
  
    &--big {
      padding: 16px 32px;
      @include T15-bold;
    }

    &--large {
      padding: 20px 40px;
      @include subheader-bold;
    }
  }

  .btn {
    &--primary {
        @include bg-blue;
        border: none;

        svg {
          fill: #fff;
        }

        &:hover {
            background-color: $deep-blue;
        }

        &:active{
            background-color: $dark-blue;
        }

        &:disabled {
            background-color: $gray-300; 
        }
    }
   
    &--secondary ,&--secondary-white {
        border:  2px solid  rgba(148, 160, 177, 0.2);
        background-color: transparent;
      
         svg {
          fill: $dark-gray;
        }

        &:hover {
            background-color: $blue;
            border-color: $blue;
            color: $white;

            svg {
              fill: $white;
            }
        }

        &:active{
            background-color: $deep-blue;
            border-color: $deep-blue;
            color: $white;

            svg {
              fill: $white;
            }
        }

       
        &:disabled {
            border-color: $gray-200; 

            svg {
              fill: $dark-gray;
            }
        }

       
    }

    &--secondary-white {
      border:  2px solid  rgba(255, 255, 255, 0.2);

      svg {
        fill: $white;
      }
    }

    &--secondary-on-dark {
      background-color: transparent;
      border: 2px solid $white;
      padding: 18px 32px;
    
      &:hover:not(:disabled) {
        background-color: $white;
        color: $dark-gray;
      }

      &:active {
        opacity: 0.8;
        color: $dark-gray;
      }

      &:disabled {
        opacity: 0.5;
      }
    }

    &--success {
      background-color: $success;

      svg {
        fill: $white;
        margin-left: -8px;
        margin-right: 10px;
            }
    }
  }

  .btn-icon {
    @include row-center;
    align-items: center;
    @include round($size: 72px);
    position: relative;
    padding: 0;

    &:disabled {
      background-color:  $gray-300;
    }

    &--primary {
      background-color:  $gray-200;

      &:hover {
        background-color: $blue;
      }
    }

    &:active {
      svg {
        fill: $white;
      }
    }


    &--secondary-white {
      &:hover, &:active {
        background-color: $white;
        border-color: $white;
        svg {
          fill:$dark-gray;
        }
      }
    }


    &--small {
      @include round($size: 56px);
    }

    &--middle {
      @include round($size: 72px);
    }

    // &:focus {
    //   background-clip: content-box;
    //   padding: 2px;
    // }
  }
  

  @media (max-width: 1200px) {
    .btn {

      &--large {
        padding: 16px 32px;
      }
    }
  
  }

  @media (max-width: 768px) {
    .btn {

      &--big, &--large {
        @include T15-bold;
      }

      &--big {
        padding: 8px 18px;
      }

      &--secondary-on-dark {
        
      }
      
      
    }
  
  }
