.tooltip {
    position: absolute;
    left: 16px;
    z-index: 9;
    font-size: 12px;
    line-height: 18px;
    font-family: 'Poppins', sans-serif;
    display: none;
    height: max-content;
    width: max-content;
    max-width: 248px;
    top: -8px;
    padding-bottom: 16px;
    transform: translate(-50%, -100%);

    &--open {
        display: block;
    }

    &-arrow {
        position: absolute;
        display: block;
        height: 10px;
        width: 10px;
        background-color: rgba(0,0,0,0.8);
        border: inherit;
        bottom: -5px;
        left: calc(50% - 8px);
        clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
        transform: rotate(-45deg);
        border-radius: 0 0 0 0.25em;
    }

    &-content {
        color: #fff;
        position: relative;
        border-radius: 4px;
        padding: 16px 18px;
        background-color: rgba(0,0,0,0.8);
    }

    &-hours {
        right: -14%;
        top: -15px;
        transform: translateY(-100%);
        max-width: 328px;
    }

    &-price {
        right: 16%;
        top: -15px;
        transform: translateY(-100%);
        max-width: 328px;
    }

    &_title {
        margin-bottom: 8px;
        font-weight: 600;
        font-size: 15px;
        line-height: 24px;
    }

    &_desc {
        // @include extra-text;
    }

    &--white {
        box-shadow: 0px 4px 64px rgba(0,0,0,0.1);
        .tooltip-content {
            background-color: #fff;
            color: $black;
            padding: 20px 22px 24px 24px;
        }

        .tooltip-arrow {
            display: none;
        }
    }
}

@media (max-width: 640px) {

    .tooltip {
        right: 0;
        left: unset;
        transform: translate(0, -100%);
        top: 10px;


        .tooltip-arrow {
            left: unset;
            right: 24px;
        }
    }
}
