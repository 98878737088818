@import './utils/normalize.scss';
@import './utils/reset.scss';
@import './utils/variables.scss';
@import './utils/mixins.scss';
@import './fonts/fonts.scss';


body,
html {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'SuisseIntl', sans-serif; // SuisseIntl Regular
  font-weight: 400;
  @include T15;
  color: $dark-gray;
  -webkit-font-smoothing: antialiased;
  scroll-behavior: smooth;
  // letter-spacing: 0.1px;

}

.app {
    height: 100%;
    width: 100%;
    min-height: unset;
    
}

.page--header {
  overflow: hidden;
  padding-top: 120px;

  @media (max-width: 768px) {
    padding-top: 88px;
    }
}

 .container {
  // width: 100%;
  // max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  // padding-left: 24px;
  // padding-right: 24px;
  padding-right: 80px;
  padding-left: 80px;

  // overflow-x: hidden;

  &-half {
    width: 100%;
    max-width: calc(1280px/2);
  }

  &-right {
    padding-right: 80px;
    // width: 100%;
    // max-width: 560px;
    // margin-left: auto;
    // margin-right: auto;
    // padding-left: 24px;
    // padding-right: 24px;
  }

  &-left {
    padding-left: 80px;

    // width: 100%;
    // max-width: 560px
    // margin-left: auto;
    // margin-right: auto;
    // padding-left: 24px;
    // padding-right: 24px;
  }
}

.app-content{ 
  // margin: 0 auto;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

.pb {
  &-72 {
    padding-bottom: 72px;
  }
}

.pt {
  &-72 {
    padding-bottom: 72px;
  }
}

.pv {
  &-72 {
    padding-top: 72px;
    padding-bottom: 72px;
  }

  &-48 {
    padding-top: 48px;
    padding-bottom: 48px;
  }

  &-64 {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  &-96 {
    padding-top: 96px;
    padding-bottom: 96px;
  }
}

.ph {
  &-48 {
    padding-left: 48px;
    padding-right:  48px;
  }
  &-80  {
    padding-left: 80px;
    padding-right: 80px;
  }
}

.mv {
  &-72 {
    margin-top: 72px;
    margin-bottom: 72px;
  }

  &-48 {
    margin-top: 48px;
    margin-bottom: 48px;
  }

  &-96 {
    margin-top: 96px;
    margin-bottom: 96px;
  }
}

.mb {
  &-8 {
    margin-bottom: 8px;
  }

  &-32 {
    margin-bottom: 32px;
  }

  &-72 {
    margin-bottom: 72px;
  }

  &-96 {
    margin-bottom: 96px;
  }

  &-104 {
    margin-bottom: 104px;
  }
}

.link:hover {
  @include hover();
}

.row {
  @include row;

  &-start {
    @include row;

    &-start {
      @include row;
      justify-content: flex-start;
      align-items: flex-start;
    }

    &-center {
      @include row;
      justify-content: flex-start;
      align-items: center;
    }

    &-stretch {
      @include row;
      justify-content: flex-start;
    }

    &-end {
      @include row;
      align-items: flex-end;
    }
  }

  &-bw {
    &-stretch {
      @include row-bw;
      align-items: stretch;
    }

    &-start {
      @include row-bw;
      align-items: flex-start;
    }

    &-center {
      @include row-bw;
      align-items: center;
    }

    &-end {
      @include row-bw;
      align-items: flex-end;
    }
  }

  &-center {

    &-start {
      @include row-center;
      align-items: flex-start;
    }

    &-center {
      @include row-center;
      align-items: center;
    }

    &-stretch {
      @include row-center;
      align-items: stretch;
    }

    &-end {
      @include row-center;
      align-items: flex-end;
    }

    &-center {
      @include row-center;
      align-items: center;
    }
  }

  &-end {
    @include row-end;


    &-start {
      @include row-end;
      align-items: flex-start;
    }

    &-stretch {
      @include row-end;
      align-items: stretch;
    }

    &-center {
      @include row-end;
      align-items: center;
    }
  }

  &-stretch {
    &-center{
      @include row;
      justify-content: stretch;
      align-items: center;
    }

    &-stretch{
      @include row;
      justify-content: stretch;
      align-items: stretch;
    }

    &-end{
      @include row;
      justify-content: stretch;
      align-items: flex-end;
    }
  }
}

.col {
  @include col;

  &-start {
    @include col;
    justify-content: flex-start;

    &-start {
      @include col;
      justify-content: flex-start;
      align-items: start
    }

    &-center {
      @include col;
      justify-content: flex-start;
      align-items: center
    }
  
    &-stretch {
      @include col;
      justify-content: flex-start;
      align-items: stretch
    }
  }

  &-center {
    @include col-center;

    &-start {
      @include col-center;
      align-items: start;
    }

    &-center {
      @include col-center;
      align-items: center;
    }
  }

  &-bw {
    @include col-bw ;
    justify-content: space-between;

    &-stretch {
      @include col-bw;
      align-items: stretch;
    }
    &-start {
      @include col-bw;
      align-items: flex-start;
    }
  }

  &-end {
    
  }
}

.svg-btn {
  &--black {
    cursor: pointer;
    fill: $dark-gray;
  
    &:hover {
      fill: $blue;
    }
  }
  &--white {
    cursor: pointer;
    fill: $white;
  }
}
.upper-case {
  text-transform: uppercase;
}

.h {
  &1{
    @include h1;
  }

  &1--l {
    @include h1--large;
  }

  &2{
    @include h2;
  }

  &3{
    @include h3;
  }

  &4{
    @include h4;
  }
}

.half {
  flex: 0.5;
}

.border {


  &-bottom, &-top, &-top-bottom,
  &-left, &-right {
    position: relative;
  }

  &-bottom:after {
    @include border($bottom: -1px, $left: 0, $right: 0);
  }

  &-top:before {
    @include border($top: 0, $left: 0, $right: 0);
  }

  &-top-bottom {
    &:before {
      @include border($top: 0, $left: 0, $right: 0);
    }
    &:after {
      @include border($bottom: 0, $left: 0, $right: 0);
    }
  }

  // vertical
  &-left:before {
    @include border($left: 0, $top: 0, $bottom: 0, $translateY: unset, $translateX: -50%);

    height: 100%;
    width: 1px;
  }

  &-right:after {
    @include border($right: 0, $top: 0, $bottom: 0, $translateY: unset, $translateX: -50%);
    height: 100%;
    width: 1px;
    z-index: 3;
  }

  // &-center-ver {
  //   @include border($left: 50%, $top: 0,  $bottom: 0, $translateY: unset, $translateX: -50%);

  //   height: 100%;
  //   width: 1px;
  // }
}

.bg {

  &-overlay {
    @include position($zIndex:3 );
    background:linear-gradient(0deg,rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
  }

  &-gray {
   @include bg-gray;
  }

  &-black {
    @include bg-black;
  }

  &-blue {
    @include bg-blue;
  }

  &-success {
    @include bg-success;
  }

  &-warn {
    @include bg-warn;
  }

  &-fail {
    @include bg-fail;
  }
}

.rotate {
  &-90 {
    transform: rotate(90deg);
  }

  &-180 {
    transform: rotate(180deg);
  }
}

.T, .t {
  &-fail {
    color: $fail;
  }

  &-warn {
    color: $warning;
  }

  &-success {
    color: $success;
  }
  &-center {
    text-align: center
  }

  &15 {
    @include T15;
  }

  &15-bold {
    @include T15-bold;
  }

  &-gray {
    color: $gray-400;
  }
}


.overlay {
  @include position($zIndex: 1);
  background-color: rgba(0, 0, 0, 0.2);
}

@media (max-width: 1200px) {
  .container {
    padding-right: 64px;
    padding-left: 64px;
  }

  .container-left {
    padding-left: 64px;
  }

  .container-right {
    padding-right: 64px;
  }
}

  @media (max-width: 1100px) {
    .container {
      padding-right: 48px;
      padding-left: 48px;
  }
  .container-left {
    padding-left: 48px;
  }

  .container-right {
    padding-right: 48px;
  }
}
  
@media (max-width: 1024px) {
  .container {
    padding-right: 24px;
    padding-left: 24px;
  }
  .container-left {
    padding-left: 24px;
  }

  .container-right {
    padding-right: 24px;
  }
}


@media (max-width: 998px) {
  .container {
    // padding-right: 24px;
    // padding-left: 24px;
  }

  .container-left {
    padding-left: 24px;
  }

  .container-right {
    padding-right: 24px;
  }

  .pv {

    &-72 {
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }
}
@media (max-width: 768px) {


  .half {
    flex: unset;
    width: 100%;
  }

  .pv {
    &-96 {
      padding-top: 48px;
      padding-bottom: 48px;
    }
  }

  .mb-8 {
    margin-bottom: 4px;
  }
}

@import './components/export.scss';
@import './routes/export.scss';

@media(max-width: 640px) {
  body,html {
    // overflow-x: hidden;
  }
  .svg-btn--black, .link,   .header-submenu_item, .header-menu_item .link, .slider .svg
  {
  
    &:hover, &:hover:not(:disabled), &:active  {
      fill: inherit !important;
      color: inherit !important;
      background-color: inherit;
      border-color: inherit !important;

      svg {
        fill: inherit !important;
        background-color: inherit !important;
      }
      .btn-icon, .slider-arrows  {
        background-color: inherit !important;
        border-color: rgba(255, 255, 255, 0.2) !important;
        svg {
          fill: $white !important;
        }
      }
    }

    .btn--primary:hover {
      background-color: $blue;
    }
    .btn--secondary:hover  {
      background-color: transparent;
      border:  2px solid  rgba(148, 160, 177, 0.2);
    }
    .btn--secondary-white:hover  {
      background-color: transparent;
      border:  2px solid  rgba(255, 255, 255, 0.2);
    }
    .btn--secondary-on-dark:hover {
      background-color: transparent;
      border: 2px solid $white;

    }

  }
}


@media(min-width: 1920px) {
  .features {
      padding-bottom: 112px;

      &_header {
      padding: 128px 0;
  }
}


.benefits {
  &-left {
  padding-top: 120px;
  }
  &-list_item {
      padding-top: 64px;
      padding-bottom: 150px;

      &-counter {
          margin-bottom: 60px;
      }

      &-title {
          margin-bottom: 32px;
      }
  }
}
}