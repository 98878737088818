.features {
    padding-bottom: 72px;
    &--bg {
        background-color: $gray-200;
    }

    &_header {
        padding: 96px 0;
        margin-bottom: 80px;
    }

    &_title {
        padding-top: 8px;
    }

    &_subtitle {
        // max-width: 536px;
        width: 50%;
    }

    &_list {
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    &_item {
        width: calc((100% - 16px*4)/4);
        // max-width: 304px;

        margin-bottom: 72px;

        &-img {
            width: max-content;
        }

        &-title {
            margin: 32px 0 24px 0;
        }

        &-subtitle {
             color: $gray-400;
             max-width: 285px;
        }
    }
}

@media(min-width: 1920px) {
}


@media(max-width: 768px) {
    .features {
        padding-bottom: 56px;
        &_header {
            padding: 72px 0 64px 0;
            margin-bottom: 64px;
        }

        &_title {
            margin-bottom: 48px;
        }
        &_title, &_subtitle {
            width: 100%;
            max-width: unset;
        }

       

        &_item {
            width: calc((100% - 16px)/2);
            margin-bottom: 40px;
        }
    }
}

@media(max-width: 768px) {
    .features {
        padding-bottom: 32px;

        &_title {
            margin-bottom: 32px;
            padding-top: 0;
        }
        &_header {
            padding: 40px 0 48px 0;
            margin-bottom: 40px;
        }
        &_item {
            width: 100%;
            margin-bottom: 32px;


            &-title {
                margin: 24px 0 8px 0;
            }

            &-subtitle {
                max-width: 274px;
            }
        }
    }
}