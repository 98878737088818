.copyright-holders {
    padding-top: 72px;
    padding-bottom: 190px;

    &_subtitle {
        align-self: flex-start;
        margin-bottom: 96px;
    }

    &_title {
        margin-bottom: 64px;
        text-align: center;
        max-width: 700px;
    }

    &_content {
        flex-wrap: nowrap;
        &-img {
            position: relative;
            margin-right: 56px;

            img{
                width: 262px;
                height: 174px; 

                &:first-child {
                    @include position($top: 30px, $right: 20px, $left: unset, $bottom: unset, );
                }
            }
        }

        &-desc {
            padding-top: 32px;
            max-width: 400px;
        }
    }
}

@media(max-width: 768px) {
    .copyright-holders {
        padding-bottom: 96px;

        &_subtitle{
            margin-bottom: 64px;
        }
        
        &_content {
            
            &-img {
                margin-right: 48px;
            }

            &-desc {
                padding-top: 0;
            }
        }
    }
}

@media(max-width: 768px) {
    .copyright-holders {
        padding-top: 48px;
        padding-bottom: 48px;

        &_title {
            margin-bottom: 24px;
            // width: calc(100% + 48px);
            // font-size: 26px;
            // line-height: 32px;
            max-width: 320px;
        }

        &_subtitle {
            margin-bottom: 48px;
        }

        &_content {
            flex-wrap: wrap;

            &-img {
                margin-right: 0;
                margin: 0 auto 80px auto;
            }
        }
    }
}