.benefits {

    &-left, &-right { 
        width: 50%;
    }
    &-left {
        background-color: black;
        color: $white;
        padding-top: 96px;
        padding-bottom: 96px;

        @include image-bg()
    }

    &-list_item {
        padding-top: 48px;
        padding-bottom: 84px;
        min-height: 300px;

        &-counter {
            align-self: flex-end;
            margin-bottom: 8px;
        }

        &-title {
            margin-bottom: 24px;
        }

        &-title, &-subtitle {
            max-width: 472px;

        }

        &:last-child:after {
            display: none;
        }
    }
}

@media(max-width: 998px) {
    .benefits  {
        &-left, &-right {
            width: 100%;
        }
        &-left {
            padding-top: 64px;
            padding-bottom: 64px;
    
            &_title {
                text-align: center;
                
            }
        }
        

        &-list_item {
            padding-bottom: 88px;

            &-counter, &-title {
                margin-bottom: 18px;
            }
        }
    }
}

@media(max-width: 640px) {
    .benefits {
        width: 100%;
        overflow: hidden;
    }
    .benefits-list_item {
        padding-top: 40px;
        padding-bottom: 40px;
        min-height: unset;
        width: 100%;

        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;

        &-title {
            order: -1;
            width: 75%;
        }

        &-counter {
            align-self: flex-start;
        }

        &-title{
            max-width: 260px;
        }

         &-subtitle {
            max-width: 272px;
           
        }
        &:last-child &-subtitle {
            max-width: 262px;
        }
        
    }
}
