.input {
    width: 100%;
    background-color: transparent;
    padding-bottom: 16px;
    // padding-top: 9px;
    border-bottom: 2px solid rgba(255, 255, 255, 0.2);
    color: $white !important;
    outline: none;
    @include T15;
    -webkit-box-shadow: inset 0 0 0 50px inherit; // disabled autocomplete bg color

    &:placeholder {
        color: rgba(255, 255, 255, 0.5);
    }

    &--active {
        border-color: $white;
    }

    &--error {
        border-color: $fail;
    }

    &-wrapper {
        padding: 24px 0 26px 0;
        width: 100%;
        position: relative;
        margin-bottom: 12px;

        .label {
            opacity: 0.4;
            @include position($bottom: unset, $right: unset);
        }
    }

    &_error-text {
        @include position($top: unset, $right: unset);
        color: $fail;
    }
}

input:-internal-autofill-selected  {
    background-color: transparent !important;
    appearance: unset !important;
    -webkit-text-fill-color: white !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-animation: autofill 0s forwards;
  animation: autofill 0s forwards;
  transition: background-color 5000s ease-in-out 0s;
  font: inherit  !important;
}

@media(max-width: 640px) {
    .input-wrapper  {
        margin-bottom: 0px;
    }
}

