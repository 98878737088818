.popup {
    @include col;
    align-items: stretch;
    width: 432px;
    background-color: $white;
    position: relative;
    padding-top: 24px;
    position: relative;
    text-align: center;

    display: none;
    
    &-overlay {
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.6);
        @include row-center;
        align-items: center;
        z-index: 103;
        flex-wrap: wrap;

        display: none;
    }

    &--open {
        display: flex;

        .popup {
            display: flex;
        }
    }

    &_close-btn {
        height: max-content;
        @include row-center;
        padding: 8px;
        align-self: flex-end;
        margin-right: 24px;
    }

    &-top {
        padding: 0 24px;
        @include col;
        align-items: center;
        margin-bottom: 56px;

        &_title {
            margin-bottom: 16px;
        }

        &_subtitle {
            max-width: 190px;
        }

        &_img {
            margin: 32px auto 56px auto;
        }
    }
   &-bottom {
       @include bg-blue;
       padding: 44px 0;
       font-size: 24px;
       line-height: 32px;
   }
}

@media(max-width: 640px) {
    .popup {
        
        width:calc(100% - 48px);
        max-width: 328px;

        &-top{
            margin-bottom: 32px;
            
            &_img {
                margin: 16px auto 32px auto;
            }

            &_title {
                font-size: 24px;
                line-height: 32px;
            }
        }
    }
}