.tag {
    display: inline-block;
    border-radius: 80px;
    cursor: default;
    outline: none;


    &--small {
        padding: 8px 16px;
    }

    &--middle {
        padding: 12px 24px;
    }

    
    &--big {
        padding: 12px 24px;
    }

    //interactive 
    &-btn {
        @include transition();
        cursor: pointer;

        &:hover:not(:disabled) {
            @include bg-gray;
        }

        &--active:hover, &--active:hover:not(:disabled) {
            background-color: $black;
            color: $white;
        }
        &:disabled {
            @include bg-gray;
            color: $gray-400;

            svg {
                fill: $gray-400;
            }
        }

        &:active {
            @include bg-black;
        }
    }
}