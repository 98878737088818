.protects-services{
    padding-top: 64px;

    &_title {
        text-align: center;
        padding-bottom: 88px;
    }
}


// @media(min-width: 1920px) {
//     .protects-services {
//         padding-top: 120px;
//         &_title  {
//             padding-bottom: 129px;
//         }
//     }
// }


@media(max-width: 768px) {
    .protects-services {
        padding-top: 64px;
        overflow: hidden;
        width: 100%;
        &_title  {
            padding-bottom: 64px;
        }
    }
}

