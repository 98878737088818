.cases {

    &-left {
        align-items: flex-start;
        position: relative;
        padding-top: 96px;
        padding-bottom: 96px;
        @include image-bg();
        overflow: hidden;
        margin-left: auto;
        background-color: $blue;
        .circle-mask {
            @include mask($size:2000px);
            z-index: 2;
        }

         &:hover {
            .circle-mask {
                box-shadow: inset 0 0 0 1840px;
                transition: box-shadow 0.3s ease-in-out;
            }
        }

        &_title {
            z-index: 3;
        }
    }

    &-slider {
        width: 100%;
        padding-top: 72px;
        padding-bottom: 96px;

        min-height: 720px;

        width: 50%;

    }
}

@media(max-width: 768px) {
    .cases-left  { 
            @include row-center;
            padding-top: 72px;
            padding-bottom: 72px;
            border-bottom: 1px solid $dividers;
            background-image: none !important;
            background-color: $white;
            .circle-mask {
                display: none;
            }

            &_title {
                text-align: center;
            }
    }

    .cases-slider {
        padding-top: 24px;
        padding-bottom: 36px;
        min-height: 520px;

        width: 100%;
    }
}

@media(max-width: 640px) {
    .cases {
        overflow: hidden;
        border-bottom: 1px solid $dividers;
    }
        .cases-left {
            padding-top: 64px;
            padding-bottom: 64px;
        }
}