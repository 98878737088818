h1,
h2,
h3,
h4,
h5,
h6,
p,
button,
textarea,
ul,ol, li, blockquote {
 margin: 0;
  padding: 0;
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
}

* {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  box-sizing: border-box;
  &::-webkit-scrollbar {
    display: none;
  }
}

li {
  list-style: none;
  // font: inherit;
}

a {
  display: block;
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

input {
  cursor: pointer;
  -webkit-appearance: none;
  -webkit-focus-ring-color: none;
  -webkit-border-radius: 0;
}

button {
  border: none;
  font: inherit;
  cursor: pointer;
  background: transparent;
  color: inherit;
  outline: none;
}

input {
  border: none;

  -webkit-box-shadow: inherit;
  -webkit-writing-mode: unset !important;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button
// input[type='time']::-webkit-inner-spin-button,
// input[type='time']::-webkit-clear-button
{
  -webkit-appearance: none;
  margin: 0;
  -webkit-writing-mode: unset !important;
}

textarea {
  border: none;
  resize: none;
}

img {
  display: block;
  border: none;
}
