@import "./MainSection.scss";
@import "./WhatProtectSection.scss";
@import "./FeaturesSection.scss";
@import "./BenefitsSection.scss";
@import "./PartnersSection.scss";
@import "./CasesSection.scss";
@import "./SectionWithImgBg.scss";
@import "./ContactSection.scss";

.custom-cursor {
    @include round($size: 320px);

    &_main, .movable {
        @include round($size: 320px);
        background: linear-gradient(136.7deg, #0085FF 14.07%, rgba(0, 133, 255, 0.5) 43.48%, rgba(0, 133, 255, 0) 87.95%);
        
    }

    &_videcontent {
        @include round($size: 320px);
        background: linear-gradient(136.7deg, #FFFFFF 14.07%, rgba(255, 255, 255, 0.5) 43.48%, rgba(255, 255, 255, 0) 87.95%);
    }
}


.movable {
    width: 40px;
    height: 40px;
    position: absolute;
    z-index: 100;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    pointer-events: none;
    // transition:  0.1s top ease-in-out, 0.1s left ease-in-out;

    @include round($size: 320px);

//     mix-blend-mode: difference;
// background-color: white;
  }

  .cursor {

    &--blue {
        background: linear-gradient(136.7deg, #0085FF 14.07%, rgba(0, 133, 255, 0.5) 43.48%, rgba(0, 133, 255, 0) 87.95%);
        // transition: 0.2s transform;
    }

    &--hidden {
        display: none;
    }
  }



.homepage {
    width: 100%;

    .main-section {
        margin-top: 0;
        margin-bottom: 0;
        padding-bottom: 0;
        &_content { 
            padding-bottom: 0;
            max-width: unset;
        }
    }
  
    &_main-section {
        // position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        // max-width: calc(100% - 720px);
        margin-right: auto;

        &_top-cursor {
            width: 100%;
            align-self: stretch;
           height: 56%;
        }

        &_title-wrapper {
            // width: calc(100% - 720px);
            width: 100%;
            padding-bottom: 72px;
            height: 360px;
        }
         
        &_title {
            height: 100%;
            @include col;
            justify-content: flex-end;
            position: relative;
            max-width: 520px;
            // margin: 0 auto;
            p {
                color: $white;
                display: flex;
                align-items: flex-end;
                word-break: keep-all;
                white-space: nowrap;
            } 
        }
        
        &_slider {
            @include position($width: 100%, $left: unset, $top: unset);
            @include image-bg();
            background-color:  $dark-gray;
            width: 50%;
            height: 360px;
            padding: 48px;
            cursor: pointer;
            z-index: 101;
            color: $white;
            &:hover{
                .btn-icon {
                    background-color: $white;
                    border-color: $white;

                    svg {
                    fill:$dark-gray;
                    }
                }
            }
          

            &-title {
                @include h4;
                line-height: 29px;
                max-width: 360px;
                z-index: 1;
            }

            &-bottom {
                position: relative;
                z-index: 1;
            }
            .slider-arrows {
                position: absolute;
                z-index: 1;
                right: 0;
                svg {
                    fill: #fff;
                    cursor: pointer;

                    &:hover {
                        fill: $gray-400;
                    }

                    &:first-child{
                        margin-right: 32px;
                    }
                }
            }

            .slide-overlay {
                @include position();
                &--1 {
                    background: $gradient-green;
                }
                &--2 {
                    background:  $main-gradient;
                }
                &--3 {
                    background: $gradient-red;
                }
                &--4 {
                    background:  $gradient-purple;
                }
                &--5 {
                    background: $gradient-green;
                }
                &--6 {
                    background:  $main-gradient;
                }
                &--7 {
                    background: $gradient-red;
                }
                &--8 {
                    background:  $gradient-purple;
                }
            }
        }
    }

    .copyright-holders_title {
        max-width: 680px;
    }
}


@media(max-width: 998px) {
    .homepage {

        &_main-section {
            padding: 0;
            flex-wrap: wrap;
         
            &_title,&_slider {
                max-width: unset;
                width: 100%;
            }

            &_title {
                max-width: 600px;
                font-size: 62px;
                line-height: 68px;

                &-wrapper {
                    width: 100%;
                    height: unset;
                    padding:  0 24px 26px 24px;
                }

                p {
                    clip-path: unset !important;
                }
            }

            &_slider {
                position: unset;
                padding: 32px 24px 24px 24px;

                .btn-icon--middle {
                    width: 48px;
                    height: 48px;
                }
            }
        }
        .main-section_content {
            padding: 0;
            top: unset;
        }

    }
}

@media(max-width: 768px) {
    .movable {
        display: none;
    }
}
@media(max-width: 640px) {
    .cursor--blue {
        background: transparent;
    }
    .homepage {
        overflow: hidden;


        .main-section{
            position: relative;
            &_bg-wrapper {
            height: calc(100vh - 284px);
            position: relative;
            }   
        }
    }  

    .homepage_main-section {
        &_title {
            max-width: 380px;
            font-size: 40px !important;
            line-height: 44px !important;

            
        }
        
        &_slider {
        height: 210px;
    }
    }
}