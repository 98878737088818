.main-section {
    // padding-top: 132px;
    // padding-bottom: 72px;
    // margin-bottom: 24px;
    &_bg { 
        &-file {
            @include position($zIndex: 2);
            object-fit: cover;
            width: 100%;
            height: 100%;
        }

        &-wrapper {
            height: 100vh;
            width: 100%;
        }

        &-overlay {
            @include position($zIndex:3 );
            background:linear-gradient(0deg,rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
        }
    }

    &_content {
        @include position($zIndex: 4, $top: 120px); 
        color: $white;
        padding-bottom: 72px;
    }
}