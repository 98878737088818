.videocontent {

    .main-section {
        margin-bottom: 0;
    }
    &-main  {
        width: 100%;
    }

    // 2 paragraphs
    &_kpi {
        &-desc {
            padding: 64px 0;

        &_item {
            max-width: 400px;

            &:first-child {
                margin-right: 80px;
            }
        }
        }
    }

    // white
    &-facts {
        &_left {
            padding-top: 96px;
            padding-bottom: 104px;

            &-title{
                 max-width: 512px;
            }
            &-subtitle {
                padding-left: 80px;
                position: relative;

                &:before {
                    @include pseudo($content: url("../../assets/cliparts/videocontent/facts_grow.svg"), $left: 0, $bottom: 0, $width: 64px, $height: 64px);

                }
            }
        }

        //blue
        &_right {
            overflow: hidden;
            position: relative;
            // z-index: 3;
            // @include bg-blue;
            background-color: $blue;
            overflow: hidden;
            &-overlay {
                // @include position();
                position: absolute;
                z-index: 1;
                background-color: $blue;
                @include  round($size: 320px);
                background: linear-gradient(136.7deg, #FFFFFF 14.07%, rgba(255, 255, 255, 0.5) 43.48%, rgba(255, 255, 255, 0) 87.95%);

            }


            .circle-mask {
                @include mask($size:2000px);
                z-index: 2;
                color: transparent;
                color: $blue;
                background: linear-gradient(136.7deg, #FFFFFF 14.07%, rgba(255, 255, 255, 0.2) 43.48%, rgba(255, 255, 255, 0) 87.95%);
                // @include  round($size: 320px);

            }
    
             &:hover {
                .circle-mask {
                    box-shadow: inset 0 0 0 1840px;
                    transition: box-shadow 0.3s ease-in-out;
                }
            }
        }

        //blue
        &_list {
            z-index: 3;
            // width: max-content;
            position: relative;

            padding: 150px 0 150px 80px;


            &-item {
                color: $white;
                flex-wrap: nowrap;
                z-index: 3;

                &:not(:last-child) {
                    margin-bottom: 104px;
                }                
                &_text {
                    margin-left: 32px;
                }
            }
        }

    }

    
    &-results {

        .circle-mask {
            @include mask($size: 2000px);
            z-index: 2;
        }
         
         &:hover {
            .content-blocks_item-mask {
                box-shadow: inset 0 0 0 1840px;
            }
        }

        &_content {
            flex-wrap: wrap;
        }

        &_block {
            width: 100%;
            min-height: 328px;
            &-count {
                color: $blue;
            }

            &-title {
                max-width: 480px;
            }
        }
    }
}


@media (max-width: 1280px) {

    .videocontent-facts_list {
        padding-left: 0;
    }
}
@media (max-width: 998px) {
    .videocontent {
        &_kpi-desc {
            padding: 48px 0 0 0;

            &_item {
               width: 48%;
                &:first-child{
                    margin-right: 16px;
                    margin-bottom: 16px;
                }
            }
        }

        &-facts{
            &_left {
            padding-top: 80px;
            padding-bottom: 64px;
        }

        &_list {
            padding-left: 0;
        }
        }
    }
}


@media (max-width: 768px) {
    .videocontent {

        .main-section {
            height: calc(100vh + 80px);
        }
        &-facts {
            &_left-subtitle {
                margin-top: 80px;
            }

            &_right {
                padding-top: 56px;
                padding-bottom: 56px;

                &-overlay, .circle-mask {
                    display: none;
                }
            }

            &_list {
                padding: 0;
                margin: 0;

                &-item {
                    &_text {
                        margin-left: 14px;
                    }

                    &:not(:last-child) {
                        margin-bottom: 16px;
                    }
                }
                &:before{
                    display: none;
                }
            }
        }

        &-results {

            &_title {
                padding: 64px 0;
            }

            &_content {
                width: calc(100% + 48px);
                margin-left: -24px;
            }

            &_block {
                min-height: 262px;
                border-bottom: 1px solid $dividers;
                padding: 32px 24px 50px 24px;

                &:after, &:before {
                    display: none;
                }
            }
        }
    }
}
@media (max-width: 640px) {
    .videocontent {
        overflow-x: hidden;
        .main-section_content {
            padding-bottom: 40px;
        }

        &-main_title {
            max-width: 220px;
            word-wrap: break-word;
            font-size: 24px;
            
        }
        
        &-main_icon-down {
            margin-right: -8px;
        }

        &_kpi {
            padding-top: 0;
            margin-top: -8px;
        }
        &_kpi-desc_item {
            width: 100%;
            &:first-child {
                margin-right: 0;
            }
           
        }
        &-facts {
            &_list-item {
                align-items: center;
                min-height: 64px;
                span {
                    height: 64px;
                    @include row-center;
                    align-items: center;
                }
                &_text {
                    max-width: 204px;
                }
            }
            &_left-subtitle {
                margin-top: 40px;
            }

            &_left, &_right {
                padding-top: 40px;
                padding-bottom: 40px;
            }
        }
        &_kpi-desc_item {
            max-width: unset;
         }

         &-results_block {
            padding: 24px 24px 48px 24px;
            min-height: 160px;
         }
    }
}