
.content-blocks {
    position: relative;
    flex-wrap: wrap;

    &_item {
        position: relative;
        padding-left: 1px;
        @include image-bg;
        background-color: transparent;
        width: 100%;
        height: 398px;
        color: black;
        overflow: hidden;

        &-mask {
            @include mask($size: 4000px);
            z-index: 2;
        }
         
         &:hover {
            .content-blocks_item-mask {
                box-shadow: inset 0 0 0 3840px;
            }
        }

       &-inner {
            @include position($width: 100%, $left: unset, $right: unset, $top: unset, $bottom: unset);
            z-index: 4;
            padding-bottom: 32px;
            padding-left:1px;
            height: 100%;
            width: 100%;
            color: white;

            @include image-bg;
        }

        &_title {
            text-align: center;
            flex: 1;
            span {
                margin: 0 auto;
            }
        }

        &_subtitle {
            color: $gray-400; 
            align-self: flex-start;
            padding-left: 32px;
        }  
    }

    .half {
        .content-blocks_item-mask{
            @include mask($size:2000px);
        }

        &:hover {
            .content-blocks_item-mask {
                box-shadow: inset 0 0 0 1840px;
            }
        }  
    }
}

@media(min-width: 1920px) {
    .contacts-blocks {
        &-mask {
            @include mask($size: 4000px);
            z-index: 2;
        }
         
         &:hover {
            .content-blocks_item-mask {
                box-shadow: inset 0 0 0 3840px;
            }
        }

        .half {
            .content-blocks_item-mask{
            @include mask($size: 2000px);
            z-index: 2;
            }
         
         &:hover {
            .content-blocks_item-mask {
                box-shadow: inset 0 0 0 1840px;
            }
        }  
        }
    }
}


.marquee {
    overflow: hidden;
    font-size: 0;
    font-family: sans-serif;
    text-transform: uppercase;
    white-space: nowrap;
    cursor: default;
    user-select: none;
  }
  
  .marquee__inner {
    font-size: 2rem;
    white-space: nowrap;
    display: inline-block;
  }

  .marquee-container {
    cursor: grab;
    cursor: -webkit-grabbing;
    width: 100%;
    height: 120px;
    overflow: hidden;
    white-space: nowrap;
  }
  .drag-span {
    position: relative;
    user-select: none;
  }

  
@media(max-width: 998px) {
    .content-blocks {
        display: block;
        width: 100%;

        &_item {
            width: calc(100% + 48px);
             margin-left: -24px;
            padding-left: 0;
            min-height: 295px;
            height: unset;
            position: relative;

            &_subtitle {
                padding-left: 24px;
                margin-left: 24px;
            }

            &:after, &:before {
                display: none;
            }
           &-inner {
                width: 100%;
                padding-left: 0 !important;
                border-top: 1px solid $dividers;
                height: 100%;
                top: 0;
                bottom: 0;

                &:after, &:before {
                    display: none;
                }   
            } 
        }
        .half {
            .content-blocks_item-bg, .content-blocks_item-inner {
                width: 100%;
            }
        }    

    }
}

@media(max-width: 768px) {
    .content-blocks {
        overflow: hidden; 

        .circle-mask, .overlay {
            display: none;
        }

        &_item {
            min-height: 240px;
            background-image: none !important;
            
            &_title {
                padding-bottom: 0;
                justify-content: center;
            }

            &-inner {
                padding-bottom: 20px;
            }
        }
    }
}